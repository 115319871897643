import React from "react";
import styled from "styled-components";
import { about } from "../../images";

export const AboutUs = () => {
  return (
    <Container id="about-us">
      <h2>¿QUIENES SOMOS?</h2>
      <div className="content-about-us">
        <div className="content-img">
          <img
            src={about}
            className="image-bg"
            width="100%"
            height="80%"
            alt="canaletas"
          />
        </div>

        <div className="content-text">
          <p>
            Somos TRU-IP, una empresa dedicada a la excelencia en ingeniería.
            Nuestra pasión por la innovación y la precisión nos impulsa a
            ofrecer soluciones especializadas en punzonado y plegado, diseño
            mecánico eléctrico y automatización.
          </p>
          <p>
            Creamos canaletas de acero inoxidable que superan los estándares de
            la industria, fusionando durabilidad, funcionalidad y estilo.
            Transformamos visiones en realidades con precisión y calidad
            excepcionales.
          </p>
        </div>
        <div className="line"></div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 3em;

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 3em;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1em;
  }

  .content-about-us {
    background: ${({ theme }) => theme.colors.black};
    color: white;
    display: flex;
    padding: 3em 3em 0.5em 3em;
    margin: 20px 2em 0 2em;

    .content-img {
      width: 60%;
      img {
        box-shadow: 5px 15px 6px rgba(0, 0, 0, 0.3);
        position: relative;
        bottom: 60px;
      }
    }

    .content-text {
      width: 40%;

      p {
        margin: 1em;
        font-size: 1.2em;
        position: relative;
        bottom: 20px;
        text-align: justify;
      }
    }

    .line {
      border-bottom: 3px solid #2643b8;
      width: 5em;
      margin-bottom: 1.5em;
    }
  }

  @media (max-width: 800px) {
    padding: 0;
    margin: 0;
    h2 {
      font-size: 2.5em;
    }

    .content-about-us {
      margin: 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(22em, 1fr));
      .content-img {
        width: 100%;
      }

      .content-text {
        margin: 0;
        width: 100%;
      }
    }
  }
`;
