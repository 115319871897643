import configs from "./configs.json";
import { includes } from "lodash";

const hostName = window.location.hostname;

const hostsProduction = ["alquiler-de-proyectores.com"];

const currentEnvironment = includes(hostsProduction, hostName)
  ? "production"
  : "development";

const currentConfig = configs[currentEnvironment];

const common = configs.common;
const contactData = configs.common.contactData;

const { version } = currentConfig;

console.log(currentEnvironment, ":", version);

const imageResizes = [];

export { currentConfig, version, common, contactData, imageResizes };
