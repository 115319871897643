import React, { useState } from "react";
import styled from "styled-components";
import { AboutUsPlans } from "./AboutUsPlans";
import {Carousel, ContactModal} from "../../components";
import { AboutUs } from "./AboutUs";


export const Home = () => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const onSetIsVisibleModal = () => setIsVisibleModal(!isVisibleModal);

  return (
    <>
      <Container>

        <Carousel />
        <AboutUsPlans onSetIsVisibleModal={onSetIsVisibleModal} />
        <AboutUs />
        <ContactModal
          isVisibleModal={isVisibleModal}
          onSetIsVisibleModal={onSetIsVisibleModal}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
`;
