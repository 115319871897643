import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../styles/constants/mediaQuery";
import { SliderBackground } from "../../images";
export const ItemCarousel = ({
  bgImage = SliderBackground,
  description,
  title,
}) => {
  return (
    <Container bgImage={bgImage}>
      <div className="content">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  width: 100%;
  height: auto;
  background-size: cover;
  color: ${({ theme }) => theme.colors.white};
  padding: 2em 1em;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      max-width: 14em;
      font-size: 2em;
      color: ${({ theme }) => theme.colors.font1};
      text-align: left;
    }
    p {
      line-height: 1.3em;
      font-size: 1.5em;
    }
  }

  ${mediaQuery.minTablet} {
    height: 40vh;
    .content {
      width: 700px;
      margin: 1em auto;

      h2 {
        font-size: 2.5em;
      }
    }
  }

  ${mediaQuery.minDesktop} {
    height: auto;

    .content {
      width: auto;
      padding: 3em 0 0 5em;
      margin: 0;

      h2 {
        font-size: 3.5em;
      }
      p {
        width: 500px;
        font-size: 1.3em;
        line-height: 1.5em;
      }
    }
  }
`;
