import React from "react";
import styled from "styled-components";
import {
  Banner2,
  Canaleta1,
  CanalesRejilla,
  BandejaEscalera,
  BandejaRanurada,
  CanaletaRanurada,
  CanalesEstandar,
} from "../../images";
import { Button } from "../../components";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const plans = [
  {
    id: 1,
    img: BandejaEscalera,

    title: "Bandejas portacables tipo escalera",
  },
  {
    id: 2,
    img: BandejaRanurada,
    title: "Bandejas portacables tipo ranurada",
  },
  {
    id: 3,
    img: CanalesRejilla,
    title: "Bandejas portacables tipo lisa",
  },

  {
    id: 4,
    img: Canaleta1,

    title: "Canaletas con rejilla en acero inoxidable",
  },

  {
    id: 5,
    img: CanalesEstandar,

    title: "Canaletas con rejilla estandar ",
  },

  {
    id: 6,
    img: CanaletaRanurada,

    title: "Canaletas ranuradas en acero inoxidable",
  },
];

export const AboutUsPlans = () => {
  const navigate = useNavigate();

  return (
    <Container id="services">
      <h2>Nuestros proyectos</h2>
      <div className="wrapper-plans">
        {plans.map((plan) => (
          <motion.div
            key={plan.id}
            className="plan-card"
            initial={{ y: "-7rem", opacity: "0" }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.7, type: "spring" }}
            whileHover={{ scale: 1.05 }}
          >
            <div className="img-item">
              <img src={plan.img} alt="canaletas" />
            </div>
            <div className="descriptions-item">
              <div className="subtitle">
                <h4> -SOMOS LOS EXPERTOS-</h4>
              </div>

              <div className="title">
                <h3>{plan.title}</h3>
              </div>
              <div className="parrafo"></div>
            </div>
          </motion.div>
        ))}
      </div>
      <div className="wrapper-contact-button">
        <Button
          width="20em"
          className="btn-primary"
          onClick={() => {
            navigate("/contact-page");
          }}
        >
          Contactar
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  padding: 4em 0;
  background-color: ${({ theme }) => theme.colors.secondary};
  background-image: url(${Banner2});
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 3em;
    text-transform: uppercase;
    font-weight: bold;
  }

  .wrapper-plans {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22em, 1fr));
    color: ${({ theme }) => theme.colors.white};
    padding: 1em;
    justify-content: center;
    gap: 1.5em;
    margin: auto;
    width: 100%;
    max-width: 71em;

    .plan-card {
      display: grid;
      grid-template-rows: 15em 1fr;
      gap: 2em;
      width: 100%;
      max-width: 22em;
      height: auto;
      background: ${({ theme }) => theme.colors.black};
      color: white;
      border: 1px;
      border-radius: 1em;
      overflow: hidden;
      margin: auto;

      .img-item {
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }

      .descriptions-item {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 1em;
        padding: 1em;
        margin: 0 0.5em 0 1em;

        .title {
          text-align: left;

          h3 {
            color: white;

            font-size: 1.5em;
            font-weight: 700;
          }
        }
        .parrafo {
          border-bottom: 2px solid #2643b8;
          width: 60px;
          margin-bottom: 1.5em;
        }

        .subtitle {
          text-align: left;
          h4 {
            padding: 0.5em 0em;
            background: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.white};
          }
          font-size: 1.2em;
          font-weight: bold;
          width: 200px;
        }
      }
    }
  }

  .wrapper-contact-button {
    display: flex;
    justify-content: center;
    padding-top: 2em;
  }
`;
