import React from "react";
import styled from "styled-components";
import { FormContact } from "../../components";

export const ContactPage = () => {
  return (
    <Container>
      <article className="item-contact">
        <h2 className="tittle-text">ENVIAME TU MENSAJE</h2>
        <FormContact />
      </article>
    </Container>
  );
};
const Container = styled.section`
  padding: 1em 0.5em;

  .item-contact {
    max-width: 500px;
    background: ${({ theme }) => theme.colors.primary};
    margin: 1em auto;
    padding: 1em;
    .tittle-text {
      padding: 0.5em 0 0 0;
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
      font-size: 1.5em;
    }
  }
`;
