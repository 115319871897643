import React from "react";
import styled from "styled-components";
import { WrapperComponent } from "../ui";

import { lighten } from "polished";
import { mediaQuery } from "../../styles/constants/mediaQuery";
import { LogoTru } from "../../images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";

export const Footer = () => {
  return (
    <Container>
      <WrapperComponent>
        <div className="footer-items">
          <div className="footer-item">
            <div className="img">
              <img src={LogoTru} alt={LogoTru} />
            </div>
            <div className="text-item">
              <p>
                Ofrecer una amplia gama de servicios especializados para
                satisfacer todas sus necesidades. Con años de experiencia en la
                industria y un equipo altamente calificado, estamos
                comprometidos en brindarle soluciones de calidad
              </p>
            </div>
          </div>
          <div className="footer-item">
            <div className="text-item">
              <h3>
                CONTACTENOS <span> ... </span>
              </h3>

              <p>
                <FontAwesomeIcon icon={faLocationDot} />
                Calle Benigno Antezana 1323 Cercado de lima
              </p>
              <p>
                <FontAwesomeIcon icon={faMobile} />
                +51 949 444 204{" "}
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} />
                gerencia@tru-ip.com
              </p>
            </div>
          </div>
          <div className="footer-item">
            <div className="text-item">
              <h3>
                NUESTROS <span> SERVICIOS </span>
              </h3>
              <p> Punzonado y plegado </p>
              <p> Automatizacion y Control </p>
              <p> Diseño Mecanico Electrico </p>
            </div>
          </div>
        </div>
        <div className="social-items"></div>
      </WrapperComponent>
      <div className="footer-bottom">
        © 2023 TRU Ingeniería - Diseño Mecanico y Tableros Eléctricos |
        Privacidad | Terminos y Condiciones
      </div>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1420px;
  margin: auto;
  background-color: ${({ theme }) => lighten(0.01, theme.colors.black)};
  .footer-items {
    padding: 3em 1em 1em 1em;
    align-items: center;
    justify-items: start;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
    gap: 1em;

    ${mediaQuery.minTablet} {
      justify-items: center;
    }

    .footer-item {
      display: flex;
      flex-direction: column;
      gap: 2em;
      margin: 0;
      padding: 0 1em;

      .img {
        img {
          width: 12em;
        }
      }
      .icon-item {
        font-size: 3em;
        display: flex;
        align-items: start;
        svg {
          color: ${({ theme }) => theme.colors.font1};
        }
      }

      .text-item {
        h3 {
          font-size: 1.5em;
          display: flex;
          gap: 15px;

          color: ${({ theme }) => theme.colors.primary};
          span {
            color: ${({ theme }) => theme.colors.white};
          }
        }

        p {
          color: ${({ theme }) => theme.colors.font3};

          display: flex;
          gap: 0.5em;
          align-items: center;
          justify-content: flex-start;

          svg {
            color: ${({ theme }) => theme.colors.primary};
            padding: 10px;
          }
        }
      }
    }
  }

  .social-items {
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 2em;
      color: ${({ theme }) => theme.colors.font1};

      .icon-item {
        font-size: 2.1em;

        a {
          color: ${({ theme }) => theme.colors.font2};
        }
      }
    }
  }

  .footer-bottom {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.font2};
    text-align: center;
    padding: 1em;
    margin: 1em auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    flex-wrap: wrap;
    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
