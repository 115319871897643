import React from "react";

import { LogoTru } from "../../images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../ui";

export const Header = ({ isMobile, onSetVisibleDrawer, onNavigate }) => {
  return (
    <HeaderContainer>
      <>
        {isMobile ? (
          <MenuMobile>
            <div className="item-logo">
              <img
                src={LogoTru}
                alt="Logo Tru"
                onClick={() => onNavigate("/")}
              />
            </div>
            <div className="bar-item" onClick={() => onSetVisibleDrawer(true)}>
              <FontAwesomeIcon
                icon={faBars}
                size="2x"
                style={{ color: "#000" }}
              />
            </div>
          </MenuMobile>
        ) : (
          <MenuDesktop>
            <div className="wrapper-desktop">
              <div className="logo-img">
                <Link to="https://tru-ip.com/">
                  <img src={LogoTru} alt="Logo Tru" />
                </Link>
              </div>
              <nav className="menu-list">
                <ul>
                  <li>
                    <Link to="/">INICIO</Link>
                  </li>

                  <li>
                    <Link to="/">SERVICIOS</Link>
                  </li>

                  <li>
                    <Link to="/">NOSOTROS</Link>
                  </li>

                  <li>
                    <Button
                      to="/contact-page"
                      onClick={() => onNavigate("/contact-page")}
                    >
                      CONTACTAR{" "}
                    </Button>
                  </li>
                </ul>
              </nav>
            </div>
          </MenuDesktop>
        )}
      </>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  background-color: #fff;
`;

const MenuDesktop = styled.div`
  width: 100%;
  background-color: #fff;

  .wrapper-desktop {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 0.3em 2em;
    margin: auto;
    max-width: 1420px;

    .logo-img {
      img {
        width: 15em;
        object-fit: contain;
        margin: auto;
      }
    }
    .menu-list {
      display: flex;
      justify-content: end;
      align-items: center;
      font-size: 1.2em;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 1.5em;
        li {
          font-size: 1em;
          color: ${({ theme }) => theme.colors.black};
          font-weight: 600;

          a {
            color: ${({ theme }) => theme.colors.black};
          }
        }
        li:hover {
          color: ${({ theme }) => theme.colors.primary};
        }
        .btn-primary {
          background: ${({ theme }) => theme.colors.primary};
          padding: 0.4em 0.5em;
          border-radius: ${({ theme }) => theme.border_radius.small};
          color: ${({ theme }) => theme.colors.tertiary};
          cursor: pointer;
        }
        .btn-primary:hover {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`;

const MenuMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1em 0.5em;

  .item-logo {
    img {
      width: 11em;
    }
  }
  .bar-item {
    color: ${({ theme }) => theme.colors.font1};
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 1em;
  }
`;
