import React from "react";

import AntCarousel from "antd/lib/carousel";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useDevice } from "../hooks";
import { mediaQuery } from "../styles/constants/mediaQuery";
import { ItemCarousel } from "../pages/home/ItemCarousel";
import {SliderBackground, SliderBackground1} from "../../src/images";

export const Carousel = ({ onClickVisibleFormContact }) => {
    const { isMobile } = useDevice();

    const dataCarousel = [
        {
            bgImage: SliderBackground,
            title: "FABRICACION DE BANDEJAS Y CANALETAS EN ACERO INOXIDABLE",
            description:
                "Calidad, Seguridad",
        },
        {
            bgImage: SliderBackground1,
            title: "FABRICACION DE TECNOLOGIA DE ULTIMA GENERACION ",
            description:
                "Calidad, Seguridad ",
        },

    ];

    return (
        <Container>
            <AntCarousel
                autoplay
                autoplaySpeed={5 * 1000}
                arrows={!isMobile}
                prevArrow={<FontAwesomeIcon icon={faChevronLeft} />}
                nextArrow={<FontAwesomeIcon icon={faChevronRight} />}
            >
                {dataCarousel.map((data, index) => (
                    <ItemCarousel
                        key={index}
                        onClickVisibleFormContact={onClickVisibleFormContact}
                        title={data?.title || "No title"}
                        description={data?.description || "No description"}
                        bgImage={data?.bgImage}
                    />
                ))}
            </AntCarousel>
        </Container>
    );
};

const Container = styled.div`
  width: 100%;
  height: auto;

  ${mediaQuery.minDesktop} {
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
      top: 46%;
    }

    .ant-carousel .slick-next,
    .ant-carousel .slick-next {
      top: 46%;
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      color: white;
      z-index: 99999;
    }

    .slick-arrow.slick-prev {
      left: 3%;
    }

    .slick-arrow.slick-next {
      right: 3%;
    }
  }

  .slick-dots-bottom {
    bottom: 1px;

    ${mediaQuery.minDesktop} {
      bottom: 4rem;
    }
  }

  .slick-dots {
    display: none !important;

    ${mediaQuery.minTablet} {
      display: flex !important;
    }

    li.slick-active {
      width: 48px;
    }

    li {
      width: 48px;

      button {
        width: 48px;
        height: 7px;
      }
    }

    ${mediaQuery.minTablet} {
      li.slick-active {
        width: 34px;
      }

      li {
        width: 34px;

        button {
          width: 34px;
          height: 5px;
        }
      }
    }
  }

  ${mediaQuery.minDesktop} {
    display: inherit;
  }
`;